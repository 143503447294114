.MuiTableRow-root.results-table {
  height: 26px !important;
  align-items: center !important;
}

.MuiTableCell-root.results-table {
  font-size: 10px !important;
  margin-left: 10px !important;
  padding: 0px !important;
  min-width: 40%;
}

.MuiTableContainer-root.results-table {
  border-radius: 8px;
}

.MuiTableRow-root.results-table:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}

.MuiTableRow-root.results-table:nth-child(odd) {
  background-color: #2a2929;
}

.results-label {
  padding: 2.5px 6px;
  color: #fff;
  border-radius: 2px;
}

.suspected-label {
  background-color: #ff786c;
}

.analysis-complete-label {
  background-color: #7486ae;
}

.inconsistent_result_label {
  background-color: #475569;
}
