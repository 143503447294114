.windowing-stats {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 10px;
  opacity: 0.8;
  margin-right: 10px;
  z-index: 1;
  margin-bottom: auto;
  color: white;
}
