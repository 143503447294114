.info-modal {
  border-radius: 6px;
}

.modal-arrow {
  left: 45px;
  top: 140px;
  background-color: #2a2929;
  position: absolute;
  transform: rotate(45deg);
  height: 11px;
  width: 11px;
}

.info-modal-content {
  background-color: #2a2929;
  border-radius: 6px;
  padding: 10px 12px;
  position: absolute;
  gap: 10px;
  left: 50px;
  top: 130px;
  height: 168px;
  width: 168px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  color: #737373;
  font-size: 12px;
}

.first-word {
  color: white;
}
