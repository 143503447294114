.slider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  z-index: 2;
  min-height: 40px;
}

#volume-slider {
  cursor: pointer;
  display: flex;
  flex: 1;
  margin: 0 15px;
  height: 6px;
  z-index: 1;
}

.rotate-180 {
  transform: rotate(180deg);
}

.arrow-icon {
  cursor: pointer;
  display: flex;
  z-index: 3;
}

input[type='range'] {
  /* removing default appearance */
  appearance: none;
  border-radius: 8px;
  background-color: rgba(241, 245, 249, 0.4);
  &:hover {
    background-color: rgba(241, 245, 249, 0.5);
  }
}

input[type='range']::-webkit-slider-thumb {
  /* removing default appearance */
  appearance: none;
  /* creating a custom design */
  height: 20px;
  width: 20px;
  background-color: #77a3f9;
  border-radius: 50%;
  border: 2px solid #fff;
}

.input-container {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
}