.main-view {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.main-view-menus {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.normal-menus {
  margin-top: 10px;
}
