.nifti-viewer {
  display: flex;
  position: relative;
  flex: 1;
}

.font-weight-500 {
  font-weight: 500;
}

.bottom-nav {
  font-size: 10px;
  margin: 10px;
  display: flex;
  opacity: 0.8;
}

.menu-spacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.top-menu-container {
  margin-top: 4%;
  z-index: 1;
  min-width: fit-content
}

.bottom-menu {
  color: white;
  display: flex;
  justify-content: space-between;
}

.bottom-spacing {
  display: flex;
  justify-content: space-between;
}

.bottom-menu-container {
  margin-bottom: 2%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-margin-bottom {
  margin-bottom: 5%;
}

