/*
  Z index to make sure drop down is in front of the image tooling or other menus
*/
.dropdown {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  z-index: 3;
}

.dropdown-header {
  background-color: #1c1c1c;
  color: #fff;
  padding: 14px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-radius: 6px;
  font-weight: 500;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #1c1c1c;
  border-radius: 6px;
  z-index: 1;
  margin-top: 7px;
}

.dropdown-item {
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  opacity: 0.5;
}

.dropdown-item.selected {
  opacity: 1;
}

.dropdown-item.disabled {
  cursor: not-allowed;
}

.disabled-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-item-warning {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-arrow {
  max-height: 16px;
  max-width: 16px;
}

.transparent-arrow {
  opacity: 0.5;
}
