/* Z index is set to 1 to ensure that the top bar is always on top of the viewer */

.top-bar {
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.space-buttons {
  display: flex;
  justify-content: space-between;
}

.top-bar-button {
  background-color: #1a1a1a;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top-bar-left-group {
  display: flex;
  gap: 14px;
  margin-left: 10px;
}

.top-bar-right {
  margin-right: 10px;
  opacity: 0.8;
}
