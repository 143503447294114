@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-VariableFont.ttf');
}

html,
body {
  background-color: #000;
  width: 100%;
  height: 100%;
  font-family: 'Inter';
  font-weight: 400;
  overscroll-behavior: none;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a:hover {
  background-color: #ddd;
}

.series {
  width: auto;
  margin: auto;
  max-width: 100%;
}


.App {
overflow: hidden;
}

@media not all and (max-width: 768px), (orientation: portrait) {
  .App {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 769px) and (orientation: landscape) {
  .App {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

