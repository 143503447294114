.MuiAccordionDetails-root.accordion-details {
  padding: 15px !important;
  padding-top: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded.results-accordion-header {
  padding-top: 0 !important;
}

span.results-accordion-header-warning {
  color: #ff786c;
}

.MuiSvgIcon-root {
  height: 18px !important;
  width: 18px !important;
  display: flex !important;
  align-items: center !important;
  flex: 1 !important;
}

.MuiAccordion-root.results-accordion-header-warning {
  border-left: 2px solid #ff786c;
}

.MuiAccordion-root.results-accordion-header {
  border-left: 2px solid #7486ae;
}

span.results-accordion-header {
  color: #7486ae;
}

.exclamation-icon {
  height: 18px !important;
  display: flex;
  align-items: center;
  flex: 1;
  width: 18px !important;
}
