.top-nav-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: #f0f0f0;
  align-items: center;
  font-size: 12px;
}

.top-nav-button {
  border-radius: 6px;
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border: 1px solid transparent;
  transition: background-color 300ms ease-in-out, border 300ms ease-in-out;
}

.blue-text {
  color: #77a3f9;
  cursor: pointer;
}

.top-nav-button-container {
  display: flex;
  justify-content: space-between;
  background-color: #1a1a1a;
  border-radius: 6px;
  width: 158px;
  height: 28px;
  align-items: center;
  padding: 0 6px;
}

.activeButton {
  background-color: #323232;
}
